import { ConfiguratorContext } from "../../context";
import { useContext, useRef, useState } from "react";
import { AsyncState, useAsyncState } from "../../hook/useAsyncState";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { useIntl } from "react-intl";
import axios, { CancelTokenSource } from "axios";
import {
  QuoteRevisionDiff,
  SortDirection,
  QuoteAuditInfo,
  QuoteAuditData,
  AXIOS_CANCEL_MSG,
  Page
} from "../../api/models";
import { FilterValue, SorterResult, SortOrder, TablePaginationConfig } from "antd/es/table/interface";
import { Button, ButtonProps, Modal, notification, Spin, Table } from "antd";
import QuoteRevisionDiffTable from "../Table/QuoteRevisionDiffTable";
import dayjs from "dayjs";
import { AsyncData } from "../../api/async_data";
import Utils from "../../util/util";
import useSWR from "swr";

type  QuoteAuditSort = SorterResult<QuoteAuditInfo> | SorterResult<QuoteAuditInfo>[]
const DEFAULT_PAGE_SIZE = 20;

const PREVIOUS_AUDIT_ID = -1;

const defaultSort = {
  columnKey: "id",
  order: 'descend' as SortOrder
};

const QuoteAuditView = () => {

  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const { quote } = useQuoteContext();

  const [quoteAuditDiff, setQuoteAuditDiff] = useState<Record<number,AsyncData<QuoteRevisionDiff>>>();

  const [sort, setSort] = useState<QuoteAuditSort>( defaultSort);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: DEFAULT_PAGE_SIZE,
    current: 1
  });

  const quoteAuditLstAsync = useQuoteAuditInfoList({
    quoteId: quote?.quoteId,
    current: pagination.current,
    pageSize: pagination.pageSize,
    sorter: sort,
  });
  const quoteAuditLst = quoteAuditLstAsync.data?.content;

  const { isReadOnly } = Utils.getQuoteState(configurator, quote);

  const loadQuoteAuditDiff = async (quoteAuditDiffAsync:AsyncData<QuoteRevisionDiff>, auditIdA:number | undefined, auditIdB?:number | undefined ) : Promise<AsyncData<QuoteRevisionDiff> | undefined> => {
    if ( !auditIdA ) return;

    quoteAuditDiffAsync.loading();
    setQuoteAuditDiff( {...quoteAuditDiff, [auditIdA]:quoteAuditDiffAsync.loading() } );

    try {
      const resp = await configurator.api.fetchQuoteAuditDiff(auditIdA, auditIdB)
      return quoteAuditDiffAsync.done(resp.data);

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch quote differences. " + errorMsg });
      quoteAuditDiffAsync.fail(e.message);
    }

    return quoteAuditDiffAsync;
  }


  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: QuoteAuditSort) => {
    setPagination(pagination);
    setSort(sorter);
  };

  const handleExpand = (expanded: boolean, record: QuoteAuditInfo) => {
    if ( expanded ) {
      if ( !quote?.quoteId ) return;

      const quoteAuditDiffAsync = quoteAuditDiff?.[ record.id ] || new AsyncData<QuoteRevisionDiff>();
      loadQuoteAuditDiff(quoteAuditDiffAsync, record.id, PREVIOUS_AUDIT_ID)
        .then( rslt => {
          if ( rslt ) {
            setQuoteAuditDiff( {...quoteAuditDiff, [record.id]:rslt } ) 
          }
        });
    }
  }

  const datasource = quoteAuditLst?.filter(v=>!!v);

  return <Table
    rowKey="id"
    scroll={{ x: true }}
    style={{ width: "100%" }}
    bordered={false}
    loading={quoteAuditLstAsync.isLoading}
    onChange={tableOnChange}
    pagination={{...pagination, total: quoteAuditLstAsync.data?.totalElements}}
    dataSource={datasource}
    expandable={{ 
      onExpand: handleExpand,
      expandedRowRender: (tm:QuoteAuditInfo) => {
        const quoteAuditDiffAsync = quoteAuditDiff?.[ tm.id ];
        return <Spin spinning={quoteAuditDiffAsync?.isLoading()} >
          {configurator.isAdmin() &&
          <div style={{display: "flex", flexDirection: "row-reverse"}}>
          <RestoreButtonModal disabled={isReadOnly} type="primary" quoteAudit={tm} />
          </div>}
          <QuoteRevisionDiffTable diff={quoteAuditDiffAsync?.val} />
        </Spin>
    }}}
    columns={ [
      {
        title: "Event Id",
        dataIndex: "id"
      },
      {
        title: "Revision",
        key: "revision",
        render: u => u.quoteInfo.revision
      },
      {
        title: "Event Time",
        key: "timestamp",
        render: u => dayjs( u.timestamp ).format("M/DD/YY h:mm:ss A")
      },
      {
        title: "User",
        key: "user",
        render: u => u.user.name
      },
      {
        title: "Event Type",
        dataIndex: "auditType"
      },
    ] }
  />

}

const RestoreButtonModal = (props: ButtonProps & {
  quoteAudit: QuoteAuditInfo
}) => {
  const configurator = useContext(ConfiguratorContext);
  const intl = useIntl();

  const {quoteAudit, ...btnProps} = props;

  const {setQuoteFormValues} = useQuoteContext();
  const [_quoteUndo, quoteUndoAsync] = useAsyncState<QuoteAuditData>();
  const [_quoteAuditDiff, quoteAuditDiffAsync] = useAsyncState<QuoteRevisionDiff>();
  const [isOpen, setIsOpen] = useState<boolean>();


  const loadQuoteUndoByAuditId = async (auditId:number ) : Promise<QuoteAuditData | undefined> => {

    quoteUndoAsync?.setLoading();
    try {
      const resp = await configurator.api.fetchQuoteByAudit(auditId)
      quoteUndoAsync?.setDone(resp.data);
      return resp.data;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch restore. " + errorMsg });
      quoteUndoAsync?.setFail(errorMsg);
    }

    return;
  }

  const loadQuoteAuditDiff = async (quoteAuditDiffAsync:AsyncState<QuoteRevisionDiff>, auditIdA:number | undefined, auditIdB?:number | undefined) : Promise<QuoteRevisionDiff | undefined> => {

    quoteAuditDiffAsync.setLoading();

    try {
      const resp = await configurator.api.fetchQuoteAuditDiff(auditIdA, auditIdB )
      quoteAuditDiffAsync.setDone(resp.data);
      return resp.data;

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      notification.error( { message: "Failed to fetch differences. " + errorMsg });
      quoteAuditDiffAsync.setFail(e.message);
    }

    return;
  }

  const handleUndo = () => {
    if( !quoteAudit.id ) return;

    loadQuoteUndoByAuditId(quoteAudit.id)
      .then( q => {
        setQuoteFormValues?.( q?.quote, true )
        setIsOpen(false);
      });
  }

  return <>
    <Button
      {...btnProps}
      onClick={() => setIsOpen(true)}
    >
      Restore 
    </Button>
    <Modal title='Restore the following changes:'
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      width={'90rem'}
      onOk={handleUndo}
      afterOpenChange={(open:boolean) => {
        if(open) {
            loadQuoteAuditDiff(quoteAuditDiffAsync, quoteAudit.id );
        }
      }}
    >
      <Spin spinning={quoteAuditDiffAsync?.isLoading()} >
        <QuoteRevisionDiffTable diff={quoteAuditDiffAsync?.val} />
      </Spin>
    </Modal>
  </>

}


interface QuoteAuditInfoListProps {
  quoteId:string | undefined
  current?: number
  pageSize?:number,
  sorter?:QuoteAuditSort
}

export const useQuoteAuditInfoList = (props: QuoteAuditInfoListProps) => {

  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const configurator = useContext(ConfiguratorContext);

  const fetcher = async ( props:QuoteAuditInfoListProps  ) : Promise<Page<QuoteAuditInfo> | undefined> => {

    const { quoteId, current, pageSize, sorter } = props;

    if ( !quoteId ) return;

    const sort = [sorter].flat().map( sorter => ({
      field: sorter?.columnKey?.toString() || defaultSort.columnKey,
      direction: ( sorter?.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));

    if ( cancelTokenSourceRef.current ) {
      cancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelSource;

    try {
      const resp = await configurator.api.fetchQuoteAudit({
            quoteId,
            page: (current || 1) - 1,
            size: pageSize || 20,
            sort,
          },
          cancelSource.token,
      )
      cancelTokenSourceRef.current = undefined;

      return resp.data;
    }
    catch(e: any) {
      const id = e.response?.data?.message || e.message ;
      if ( id === AXIOS_CANCEL_MSG ) return;
      throw e;
    }
  }

  // Use SWR for data fetching
  return useSWR([
        'fetchQuoteAudit',
        props
      ],
      ([_k, p]) => fetcher(p),
      {
        //maybe unnecessary
        revalidateOnFocus: false,
        //maybe unnecessary
        dedupingInterval:1
      }
  );
};


export default QuoteAuditView;

