import React, {useState} from "react";
import {Image, Modal, Space, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import {MISSING_IMAGE} from "../api/models";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const InfoButtonModal = (props:{
    title?: string | React.ReactNode
    info: {
        notes?: string
        fullImageUrl?: string;
        thumbnailImageUrl?: string;
    }
}) => {

    const {title, info} = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);


    return <>
        <Tooltip title={"View Assembly"}>
            <InfoCircleOutlined onClick={() => setIsOpen(true)}  />
        </Tooltip>
        <Modal
            title={title}
            open={isOpen}
            onOk={() => setIsOpen(false)}
            onCancel={() => setIsOpen(false)}
            styles={{
                content: {
                    minWidth: "40rem",

                }
            }}
        >
            <Space align={"start"}>
                {!!info.thumbnailImageUrl && <>
                  <div style={{
                      padding: "1rem",
                      marginRight: "1rem",
                      backgroundColor: "rgba(0,0,0,0.03)",
                      borderRadius: "12px",
                  }}>
                    <Image src={info?.thumbnailImageUrl}
                           preview={false}
                           fallback={MISSING_IMAGE}
                    />
                  </div>
                </>}
                <div className={"markdown-body"} >
                    <Markdown remarkPlugins={[remarkGfm]}>{info?.notes || "There are no notes on this assembly."}</Markdown>
                </div>
            </Space>
        </Modal>
    </>
}

export default InfoButtonModal;