import "./AssemblySelectionTable.css"
import "github-markdown-css/github-markdown.css"
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  notification,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import {
  CategoryMetadata,
  Category,
  CustomOptionType,
  BaseCategory,
  AssemblyOption,
  AssemblyBase,
  PAGINATION_MAX_PAGE_SIZE,
  AssemblyExceptionType,
  QuoteAssemblyException,
  DEFAULT_THROTTLE,
} from "../../api/models";
import {
  MoreOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Utils from "../../util/util";
import {ColumnType} from "antd/lib/table";
import React, {useMemo, useContext, useEffect, CSSProperties, useState, ChangeEvent, useCallback } from "react";
import {ConfiguratorContext } from "../../context";
import {useIntl} from "react-intl";
import Title from "antd/lib/typography/Title";
import RuleDebugOutputModal from "../RuleDebugOutputModal";
import BMButton from "../BMButton";
import AssemblyExceptionButtonModal from "./AssemblyExceptionButtonModal";
import EditCustomOptionButtonModal from "../EditCustomOptionButtonModal";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { throttle } from "lodash";
import { FilterValue, SorterResult, SortOrder } from "antd/es/table/interface";
import {CategoryOptionsFilter} from "../../api";
import { MenuItemType } from "antd/es/menu/interface";
import _ from "lodash";
import dayjs from "dayjs";
import useCategoryOptionsPage from "../../swr/useCategoryOptionsPage";
import useSelectAssemblyPriceDifference from "../../swr/useSelectAssemblyPriceDifference";
import useSelectCustomOptionPriceDifference from "../../swr/useSelectCustomOptionPriceDifference";
import useAssemblyExceptions from "../../swr/useAssemblyExceptions";
import useCustomOptions from "../../swr/useCustomOptions";
import useComputedValid from "../../swr/useComputedValid";
import useCategory from "../../swr/useCategory";
import InfoButtonModal from "../InfoButtonModal";

type SelectionSort = SorterResult<SelectionInfo> | SorterResult<SelectionInfo>[];

const DEFAULT_SORT = {
  columnKey:  'name',
  order: 'ascend' as SortOrder,
}

const DEFAULT_FILTER = {
  imported: true,
  showObsolete: false,
  showIncompatible: false,
}

export interface SelectionInfo {
  key:string,
  option:AssemblyOption | CustomOptionType
}

export function isAssemblyOption(option: AssemblyOption | CustomOptionType | undefined) : boolean {
  if ( !option ) return false;
  return 'bom' in option;
}

export function asAssemblyOption(option: AssemblyOption | CustomOptionType | undefined) : AssemblyOption | undefined {
  return isAssemblyOption(option) ? option as AssemblyOption : undefined;
}
export function asCustomOption(option: AssemblyOption | CustomOptionType | undefined) : CustomOptionType | undefined {
  return !isAssemblyOption(option) ? option as CustomOptionType : undefined;
}

const warningTextStyle = {color: "red", fontWeight: 'bold'};
const infoTextStyle = {color: "blue", fontWeight: 'bold'};

const DEFAULT_PAGINATION = {
  pageSize: 5,
  current: 1,
}
const AssemblySelectionTable = (props: {
  loading?: boolean
  selectedCategory: BaseCategory | undefined
  onSelectOption: (c:BaseCategory, o:AssemblyBase | CustomOptionType | undefined)=>void
  onClearSelections: (c:BaseCategory)=>void
  filterOptionsQuery: string | undefined
  optionNotes:Record<number,string>
  onUpdateOptionNotes:(a:number | undefined, note:string | undefined)=>void
  percentDiscount: number | undefined
  disableAssemblyPricing: boolean | undefined
}) => {

  const intl = useIntl();
  const configurator = useContext(ConfiguratorContext);
  const isEngineering = configurator.isEngineering();
  const isAdmin = configurator.isAdmin();

  const { quote, adminView, selectedOptions, selectedCustomOptions, selectedModel} = useQuoteContext();

  const modelId = selectedModel?.modelInfo.id;

  const selectedCategoryId = props.selectedCategory?.id;
  const selectedCategoryAsync = useCategory({id: selectedCategoryId})
  const selectedCategory = selectedCategoryAsync.data;

  const assemblyExceptions = useAssemblyExceptions({quoteId: quote?.quoteId})

  const computedValid = useComputedValid({
    modelId: selectedModel?.modelInfo.id,
    options: {
      currentSelections: Object.values(selectedOptions || {}).flat(),
      customOptions: Object.values( selectedCustomOptions || {} ).flat(),
      quoteRevisionId: quote?.displayRevisionId,
    }
  });

  //note: selections is required for pricing
  const customOptionsAsync = useCustomOptions({
    quoteRevisionId: quote?.displayRevisionId,
    selections: Object.values(selectedOptions || {}).flat()
  })
  const customOptions = customOptionsAsync.data?.filter( co => co.category?.id === selectedCategoryId ) || [];

  const [categoryOptionsFilter, setCategoryOptionsFilter] = useState<CategoryOptionsFilter>(DEFAULT_FILTER);

  const [pagination, setPagination] = useState<TablePaginationConfig>(DEFAULT_PAGINATION);
  const [sort, setSort] = useState<SelectionSort>(DEFAULT_SORT);

  //if menu sort filter changes, update the selection filter
  useEffect(() => {
    const filter = {
      ...categoryOptionsFilter,
      metadataSearch: props.filterOptionsQuery,
    };
    setCategoryOptionsFilter(filter);
  }, [props.filterOptionsQuery]);

  //on initial conditions changing, reset the pagination
  useEffect(() => {
    setCategoryOptionsFilter({
      ...DEFAULT_FILTER,
      metadataSearch: props.filterOptionsQuery,
    });
  }, [selectedCategoryId, modelId, selectedOptions, selectedCustomOptions])

  const {
    isOrder,
    isPending,
    isReadOnly,
  } = Utils.getQuoteState(configurator, quote);


  const categoryOptions = useCategoryOptionsPage({
    modelId: selectedModel?.modelInfo.id,
    categoryId: selectedCategoryId,
    options: {
      currentSelections: Object.values(selectedOptions || {}).flat(),
      customOptions: Object.values(selectedCustomOptions || {}).flat(),
      quoteRevisionId: quote?.displayRevisionId,
    },
    filter: {
      ...categoryOptionsFilter,
      includePricing: !props.disableAssemblyPricing
    },
    current: ( pagination?.current || DEFAULT_PAGINATION.current ) - 1,
    pageSize: pagination?.pageSize || DEFAULT_PAGINATION.pageSize,
    sorter: sort
  });

  const columnFilterOptions = useCategoryOptionsPage({
    modelId: selectedModel?.modelInfo.id,
    categoryId: selectedCategoryId,
    options: {
      currentSelections: Object.values(selectedOptions || {}).flat(),
      customOptions: Object.values(selectedCustomOptions || {}).flat(),
      quoteRevisionId: quote?.displayRevisionId,
    },
    filter: {
      ...categoryOptionsFilter,
      includePricing: !props.disableAssemblyPricing
    },
    current: 0,
    pageSize: PAGINATION_MAX_PAGE_SIZE,
    sorter: sort,
  });

  const getAssemblyLabel = (a:AssemblyOption) => !!a.label?.length ? a.label : a.bomDescription;

  const columnFilterValues = useMemo( () => 

    columnFilterOptions.data?.content.map( a => {

      const asm = {'name': getAssemblyLabel(a)};

      const metadata = a.metadata
      .map( md => {
        const v = Utils.getMetadataValue(md);
        return v ? ({ [md.name!]: String(v) }) : undefined;
      })
      .filter(v => !!v)
      .reduce( (acc, val) => Object.assign(acc, val), {})

      return Object.assign(asm, metadata);
    })
    .reduce( (acc, val) => {
      //merge array of objects into one object with values as sets (to remove duplicates)
      //eg [ {a, b}, {a, c}, {a, c}, {d, e} ] => {a: [a, c], d: [e]}
      Object.keys(val).forEach( k => {
      //console.log( "reduce", k );
        if ( !acc[k] ) acc[k] = new Set<string>();
        acc[k].add(val[k]);
      });
      return acc;
    }, {}), 
  [columnFilterOptions.data?.content]);

  const handleTableChange =  useCallback(throttle( (pagination:TablePaginationConfig, filters:Record<string, FilterValue | null>, sorter: SelectionSort) => {

    if ( !_.isEqual( categoryOptionsFilter.columnFilterValues || {}, filters) )  {
      setCategoryOptionsFilter({
        ...categoryOptionsFilter,
        columnFilterValues: filters,
      });
    }

    setSort(sorter);
    setPagination(pagination);
  }, DEFAULT_THROTTLE), [] );


  const selectedOptionLst = ( selectedCategoryId && selectedOptions?.[ selectedCategoryId ] ) || [];
  const selectedCustomOptionLst = ( selectedCategoryId && selectedCustomOptions?.[ selectedCategoryId ] ) || [];

  const isValidLeadTime = !Utils.isWithinLeadTime(quote?.productionDate, selectedCategory?.leadTimeDays);
  const overrideLeadTime = isAdmin || isEngineering;

  const saveUserSelection = async (quoteRevisionId:number | undefined, selection:SelectionInfo | undefined) : Promise<void> => {
    if ( !quoteRevisionId ) return;
    if ( !selection ) return;

    try {

      await configurator.api.saveUserSelection(quoteRevisionId, {
        assemblyId: asAssemblyOption(selection.option)?.id,
        customOptionId: asCustomOption(selection.option)?.id,
      });

    } catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.message });
      console.log( "Failed to load category options. " + errorMsg );
    }

    return;
  }

  const getLeadTimeAlertMsg = () : string | undefined => {
    if ( isValidLeadTime ) return;

    if ( overrideLeadTime ) {
      return `Warning! Changes are within ${selectedCategory?.leadTimeDays} days of the Production Date.`;
    }
    else {
      const rslt = new Array<string>();
      rslt.push( `Selections in this category cannot be changed within ${selectedCategory?.leadTimeDays} days of the Production Date.` );
      const primaryEngineer = quote?.salesTeam?.engineers?.find(v=>v);
      if ( primaryEngineer ) {
        rslt.push(`Please reach out to ${primaryEngineer.name} (${primaryEngineer.email}) if you need additional assistance.`);
      }
      return rslt.join( " " );
    }
  };

  const getAssemblyExceptionDisabledMsg = () : string | undefined => {

    return !quote?.displayRevisionId ? "The quote is still loading."
        : !selectedCategory ? "A category must be selected."
        : undefined;

  }

  const getCategoryDisabledMsg = () : string | undefined => {

    return !quote?.displayRevisionId ? "The quote is still loading."
      : !selectedCategory ? "A category must be selected."
      : ( !(overrideLeadTime || isValidLeadTime) ) ? getLeadTimeAlertMsg()
      : ( isOrder && isReadOnly ) ? "A change order is required to modify assemblies."
      : ( isPending && isReadOnly ) ? "Assemblies cannot be modified while approval is pending."
      : isReadOnly ? "The quote is currently read-only"
      : undefined;

  }

  const getDebugDisabledMsg = () : string | undefined => {
    const disabledMessage = getCategoryDisabledMsg();
    return !!disabledMessage ? disabledMessage
            : !(isAdmin || isEngineering) ? "Only engineering can clear"
            : undefined;
  }

  const isCategoryDisabled = () : boolean => {
    return !!getCategoryDisabledMsg()
  }

  const getCategoryWarningMsg = () : string | undefined => {
    if ( overrideLeadTime && !isValidLeadTime ) return getLeadTimeAlertMsg();

    return;
  }

  const isCategoryWarning = () : boolean => {
    return !!getCategoryWarningMsg()
  }

  const isRowDisabled = (s:SelectionInfo) :boolean => {

    if( isCategoryDisabled() ) return true;

    const asm = asAssemblyOption(s.option);
    if ( !(isEngineering || isAdmin ) && asm?.obsoletedAt ) return true;
    if ( !!asm?.incompatible?.length ) return true;

    return false;
  }

  const isSelected = (s:SelectionInfo):boolean => {
    const asm = asAssemblyOption(s.option);
    const co = asCustomOption(s.option);

    const isSelectedAssembly = asm && selectedOptionLst.includes(asm.id)
    const isSelectedCustomOption = co && selectedCustomOptionLst.includes(co.id)
    return isSelectedAssembly || isSelectedCustomOption || false;
  }

  const buildSelectionInfo = (o:CustomOptionType | AssemblyOption ) :SelectionInfo => {
    const asm = asAssemblyOption(o);
    if( asm ) {
      return {key: `assembly-${asm.id}`, option:asm};
    }

    const co = o as CustomOptionType;
    return {key: `customOption-${co.id}`, option:co};
  }

  const customSelections:SelectionInfo[] | undefined = customOptions?.map(buildSelectionInfo) || [];

  const assemblySelections:SelectionInfo[] | undefined = categoryOptions.data?.content?.map(buildSelectionInfo) || [];

  const pgSz = ( pagination?.pageSize || DEFAULT_PAGINATION.pageSize) - 1; //minus 1 for current selection
  const pgNum = ( pagination.current || DEFAULT_PAGINATION.current ) - 1;
  const customSelectionPage = customSelections.slice( pgSz * pgNum, ( pgSz * pgNum) + pgSz );
  const selections:SelectionInfo[] | undefined = [ 
    assemblySelections.filter(isSelected), 
    customSelections.filter(isSelected), 
    customSelectionPage.filter(a => !isSelected(a)), 
    assemblySelections.filter(a => !isSelected(a)) 
  ].flat().filter(v=>v);
  const dataSource:SelectionInfo[] | undefined = selections.slice( 0, pagination.pageSize );

  const obsoletedOptions: SelectionInfo[] = dataSource.filter(s => asAssemblyOption(s.option)?.obsoletedAt);

  const selectedRows = dataSource?.filter( isSelected );
  const disabledSelectedRows = selectedRows?.filter( isRowDisabled );

  const handleSelectRow = async (s:SelectionInfo) => {
    if (!selectedCategory) return;
    if (!(overrideLeadTime || isValidLeadTime)) return;
    if (isRowDisabled(s)) return;

    saveUserSelection(quote?.displayRevisionId, s );

    props.onSelectOption(selectedCategory, s.option);
  };

  //if category allows multiple or there are multiple selected, allow multiple selection
  const showMultiple = selectedCategory?.allowMultiple || selectedRows.length > 1;

  const showMultipleWarning = showMultiple && !selectedCategory?.allowMultiple

  const handleClearDisabled = () => {
    if (!selectedCategory) return;

    selectedRows?.filter( isRowDisabled ).forEach( s => {
       props.onSelectOption(selectedCategory, s.option );
    });
  }

  const handleAddCustomOption = async (customOption:CustomOptionType | undefined)  => {
    customOptionsAsync.mutate();

    if (!selectedCategory) return;

    props.onSelectOption(selectedCategory, customOption);
  }

  const handleEditCustomOption = async (customOption?:CustomOptionType | undefined)  => {
    customOptionsAsync.mutate();

    if (!selectedCategory) return;

    const selectedLst =  selectedCustomOptions?.[ selectedCategory.id ] || [];

    //if already selected, revalidate
    if ( !customOption || ( customOption?.included === selectedLst.includes(customOption.id) ) ) {
      if ( customOption?.included ) {
        computedValid.mutate();
      }
    }
    else {
      props.onSelectOption(selectedCategory, customOption);
    }
  }

  const handleAddAssemblyException = (ae:QuoteAssemblyException) => {
    assemblyExceptions.mutate();

    if (!selectedCategory ) return;
    if (isReadOnly) return;

    props.onSelectOption(selectedCategory, ae.assembly);
  }

  const handleRemoveAssemblyException = () => {
    assemblyExceptions.mutate();
  }

  const handleClear = () => {
    if (!selectedCategory) return;
    props.onClearSelections(selectedCategory);
  }

  const handleChangeSearchFilter = (e:ChangeEvent<HTMLInputElement>) => {
    setCategoryOptionsFilter({
      ...categoryOptionsFilter,
      metadataSearch: e.target.value,
    });
  }

  const optionActionItems = new Array<MenuItemType>();
  optionActionItems.push( {
    key: "clearBtn",
    label: <BMButton type="text" className="ghostBmButton"
      disabled={!!getCategoryDisabledMsg()}
      onDisabledClick={() => notifyDisabled(getCategoryDisabledMsg())}
      onClick={handleClear}>Clear</BMButton>
  } );
  optionActionItems.push( {
    key: "debugBtn",
    label:
        <RuleDebugOutputModal
          category={selectedCategory}
          type="text"
          obsoletedOptions={obsoletedOptions}
        />
  } );


  const getColumnFilterProperties = (columnName:string | undefined) : ColumnType<SelectionInfo> | undefined => {
    if ( !columnName?.length ) return;

    const values = columnFilterValues?.[ columnName ];
    const filters = values ? [...values]
      .sort( (a,b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map( v => ({ id:columnName, text: v, value: v })) : undefined;

    return {
      filters,
      sorter: {
        multiple: 1
      },
      filterSearch: true,
      filterMultiple: true,
    }
  }

  const getColumnForMetadata = ( md:CategoryMetadata ) : ColumnType<SelectionInfo> => ({
      ...getColumnFilterProperties(md.name),
      key: md.name, 
      title: md.name,
      render: (s:SelectionInfo) => {
        const m = s.option?.metadata?.find( md1 => md1.categoryMetadata.id == md.id);
        return m == null ? "" : Utils.getMetadataValue(m);
      },
    });

  const metadataColumns:ColumnType<SelectionInfo>[] = 
    selectedCategory?.metadata?.filter( md => adminView || md.visibleInConfigurator )
    .sort((a,b) => (a.sortOrder || 0 ) - ( b.sortOrder || 0 ) )
    .map( md  => getColumnForMetadata( md ) ) || [];


  const labelStyle =  { wordWrap: "break-word", wordBreak: "break-word", width: "30rem" } as CSSProperties;

  const columns:ColumnType<SelectionInfo>[] = [
    {
      key: "name",
      title: "Name",
      fixed: "left",
      sorter: {
        multiple: 1
      },
      render: (s: SelectionInfo) => {
        const asm = asAssemblyOption(s.option);
        const co = asCustomOption(s.option);
        if ( asm ) {
          return <AssemblyDetail 
            labelStyle={labelStyle}
            assembly={asm} 
            note={props.optionNotes[asm.id]} 
            onUpdateNote={(note) => props.onUpdateOptionNotes(asm.id, note) } />
        }
        else if(co) {
          return <CustomOptionDetail 
            category={selectedCategory}
            labelStyle={labelStyle}
            disabled={isRowDisabled(buildSelectionInfo(co))}
            customOption={co} 
            onChange={handleEditCustomOption} />
        }
      },
    },
    ...metadataColumns
  ];

  const notifyDisabled = (msg:string | undefined) => {

    if ( !!msg ) {
      notification.warning({message: msg });
    }
  }


  const handleToggleShowIncompatible = (checked:boolean) => {
    setCategoryOptionsFilter({  ...categoryOptionsFilter, showIncompatible: checked });
  }

  const handleToggleShowObsolete = (checked:boolean) => {
    setCategoryOptionsFilter({  ...categoryOptionsFilter, showObsolete: checked });
  }

  const filterActionItems = new Array<MenuItemType>();

  filterActionItems.push( {
    key: "filterIncompatible",
    label:
    <div >
      <label>
        <Checkbox
          onChange={(e) => handleToggleShowIncompatible(e.target.checked)}
          checked={categoryOptionsFilter.showIncompatible}
        />
        <span style={{marginLeft: "0.5rem"}}>Show Incompatible</span>
      </label>
    </div>
  } );

  filterActionItems.push( {
    key: "filterObsolete",
    label:
    <div >
      <label>
        <Checkbox
          onChange={(e) => handleToggleShowObsolete(e.target.checked)}
          checked={categoryOptionsFilter.showObsolete}
        />
        <span style={{marginLeft: "0.5rem"}}>Show Obsolete</span>
      </label>
    </div>
  } );

  return <>

    <style>
      {`
        .ant-table-cell {
          background-color: white !important;
        }
      `}
    </style>

    <Space align={"baseline"}>
      <Title level={5}>
        {Utils.stripSortingPrefix(selectedCategory?.name)} Options
      </Title>
      {(selectedCategory?.thumbnailImageUrl?.length || selectedCategory?.notes?.length) &&
          <InfoButtonModal
              title={ (isEngineering || isAdmin)
                ? <Link to={`/categories/${selectedCategory.id}`} target="_blank">{Utils.stripSortingPrefix(selectedCategory?.name)}</Link>
                : <>{Utils.stripSortingPrefix(selectedCategory?.name)}</>}
              info={selectedCategory}
          />}
    </Space>
    <Row justify={"space-between"} align="middle" style={{width:"100%", marginBottom: ".5rem"}}>
      <Col>
        <Space direction="horizontal">
          <Space.Compact>
            <Input value={categoryOptionsFilter.metadataSearch} 
              onChange={handleChangeSearchFilter} 
              placeholder="Filter options" 
              allowClear style={{minWidth: "20rem" }} />
            <Dropdown trigger={["click"]}
              placement="bottomRight"
              menu={{
                items:filterActionItems,
              }} >
              {/* this div is to avoid a warning with strict mode */}
              <div>
                <Button icon={<MoreOutlined/>} />
              </div>
            </Dropdown>
          </Space.Compact>
        </Space>
      </Col>
      <Col>
        <Space direction="horizontal">

          {(isEngineering || isAdmin ) &&
          <AssemblyExceptionButtonModal type="primary"
            onAdd={handleAddAssemblyException}
            onDelete={handleRemoveAssemblyException}
            disabled={!!getAssemblyExceptionDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getAssemblyExceptionDisabledMsg())}
            selectedCategory={selectedCategory}
          >
            Assembly Exceptions
            </AssemblyExceptionButtonModal> }

          <EditCustomOptionButtonModal type="primary" 
            onChange={handleAddCustomOption} 
            disabled={!!getCategoryDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getCategoryDisabledMsg())}
            categoryId={selectedCategory?.id}
          >
            Add Custom Option
            </EditCustomOptionButtonModal>
          <BMButton type="primary" key="clearBtn"
            disabled={!!getDebugDisabledMsg()}
            onDisabledClick={() => notifyDisabled(getDebugDisabledMsg())}
            onClick={handleClear}>Clear</BMButton>
          <RuleDebugOutputModal
            category={selectedCategory}
            type="primary"
            key="debugBtn"
            obsoletedOptions={obsoletedOptions}
          />
        </Space>
      </Col>
    </Row>

    {isCategoryDisabled() &&
    <Alert type="info" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={getCategoryDisabledMsg()} />
    }
    {isCategoryWarning() &&
    <Alert type="warning" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={getCategoryWarningMsg()} />
    }
    {(!isCategoryDisabled() && !!disabledSelectedRows?.length ) &&
    <Alert type="error" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={"Some of the selections are no longer valid."}
      action={<><Button onClick={handleClearDisabled}>Clear </Button></>} />
    }
    {showMultipleWarning &&
    <Alert type="error" style={{width:"100%", marginBottom: ".5rem"}}
      showIcon
      message={"Multiple selections, including custom options, are not valid for this category."}
       />
    }


    <Table
      key={"assemblySelectionTable-" + selectedCategoryId}
      data-testid="assemblySelectionTable"
      bordered
      loading={props.loading || categoryOptions.isLoading}
      style={{ width: "100%" }}
      columns={columns}
      dataSource={dataSource}
      pagination={{...pagination, total: categoryOptions.data?.totalElements}}
      onChange={handleTableChange}
      onRow={(record, _rowIndex) => {
        return {
          onDoubleClick: () => handleSelectRow(record)
        };
      }}
      rowKey="key"
      rowSelection={{
        type: showMultiple ? "checkbox" : "radio",
        onSelect: handleSelectRow,
        selectedRowKeys: selectedRows.map(s => s.key ),
        hideSelectAll: true,
        getCheckboxProps: (record) => {
          return { 
            disabled: isRowDisabled(record)
          };
        }
      }}
      scroll={{ x: true }}
    />
  </>
}


const AssemblyDetail = (props:{ 
  assembly:AssemblyOption,
  note:string
  onUpdateNote:(n:string | undefined) => void
  labelStyle:CSSProperties
}) => {
  const {assembly} = props;
  const asmLbl = (!!assembly.label?.length ? assembly.label : assembly.bomDescription);

  const { quote, selectedOptions, selectedCustomOptions, selectedModel} = useQuoteContext();

  const configurator = useContext(ConfiguratorContext);
  const isEngineering = configurator.isEngineering();
  const isAdmin = configurator.isAdmin();

  const currentSelections = Object.values(selectedOptions || {}).flat().sort();
  const customOptions = Object.values(selectedCustomOptions || {}).flat().sort();
  const assemblyExceptions = useAssemblyExceptions({quoteId: quote?.quoteId})

  const selectedAssemblyPriceDifference = useSelectAssemblyPriceDifference({
    assemblyId: assembly.id,
    ctx: {
      selectedModelId: selectedModel?.modelInfo.id,
      currentSelections,
      customOptions,
      quoteRevisionId: quote?.displayRevisionId,
      percentDiscount: quote?.percentDiscount
    }
  })

  const promptOptionNotes = () => {
    const note = prompt("Enter option notes", props.note || "");
    props.onUpdateNote(note || undefined);
  }

  const isTempDemand = assemblyExceptions.data
      ?.filter(ae => ae.type === AssemblyExceptionType.TEMP_DEMAND )
      .filter( ae => ae.assembly.id === props.assembly.id )
      .map(ae => `${ae.reason} by ${ae.createdBy?.name}` ).join(", ")

  const isObsoleteException = assemblyExceptions.data
      ?.filter(ae => ae.type === AssemblyExceptionType.OBSOLETE )
      .filter( ae => ae.assembly.id === props.assembly.id )
      .map(ae => `${ae.reason} by ${ae.createdBy?.name}` ).join(", ")

  const isIncompatibleException = assemblyExceptions.data
      ?.filter(ae => ae.type === AssemblyExceptionType.RULE_OVERRIDE )
      .filter( ae => ae.assembly.id === props.assembly.id )
      .map(ae => `${ae.reason} by ${ae.createdBy?.name}` ).join(", ")


  return <>
    {(!!isObsoleteException?.length || !!isIncompatibleException?.length ) &&
        <Tooltip title={isObsoleteException || isIncompatibleException}>
          <div style={infoTextStyle}>(Exception)</div>
        </Tooltip>}

    {(!!isTempDemand?.length ) &&
      <Tooltip title={isTempDemand}>
        <div style={infoTextStyle}>(Temporary Demand)</div>
      </Tooltip>
    }
    {!isObsoleteException &&
    <div style={warningTextStyle}>
      {assembly.obsoletedAt &&
          <Tooltip title={`Obsoleted on ${dayjs(assembly.obsoletedAt).format("MM/DD/YYYY")}`}>
          (Obsolete)
          </Tooltip>
        }
    </div>}
    {!isIncompatibleException &&
    <div style={warningTextStyle}>
      {!!assembly.incompatible?.length &&
          <Tooltip title={assembly.incompatible}>
          (Incompatible)
          </Tooltip>}
    </div> }
    <div style={{ ...props.labelStyle }} >
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <Space>
          <div>{asmLbl}, <span style={{whiteSpace:"nowrap"}}>{assembly.bom}</span></div>
          {(assembly.thumbnailImageUrl?.length || assembly.notes?.length) &&
              <InfoButtonModal
                  title={ (isEngineering || isAdmin)
                    ? <Link to={`/assemblies/${assembly.id}`} target="_blank"><div>{asmLbl}, <span style={{whiteSpace:"nowrap"}}>{assembly.bom}</span></div></Link>
                    : <div>{asmLbl}, <span style={{whiteSpace:"nowrap"}}>{assembly.bom}</span></div> }
                  info={assembly} />}
        </Space>
        <div style={{whiteSpace: "nowrap", minWidth: "5rem", textAlign:"right"}}>
        {(selectedAssemblyPriceDifference.data || 0) != 0 &&
          <span style={{fontWeight: "600"}}>&nbsp;{Utils.formatMoneyWithSign(selectedAssemblyPriceDifference.data)}</span>}
        </div>
      </div>
      {assembly.selectionRequiresUserInput && (
        <div style={{ marginTop: "5px" }}>
          <strong>Option Notes: </strong>{" "}
          <span>{props.note || "None"}</span>{" "}
          <EditOutlined onClick={promptOptionNotes} />
        </div>
      )}
    </div>
    {(!!isTempDemand?.length ) && <div style={{fontStyle:"italic"}}>Note: {isTempDemand}</div> }
  </>;
}

const CustomOptionDetail = (props:{
  category: Category | undefined
  customOption:CustomOptionType 
  disabled?:boolean
  onChange:(co?:CustomOptionType)=>void
  labelStyle:CSSProperties
}) => {

  const { customOption, disabled } = props;
  const configurator = useContext(ConfiguratorContext);

  const { quote, selectedOptions, selectedCustomOptions, selectedModel} = useQuoteContext();
  const currentSelections = Object.values(selectedOptions || {}).flat();
  const customOptions = Object.values(selectedCustomOptions || {}).flat();

  const priceDifference = useSelectCustomOptionPriceDifference({
    customOptionId: customOption.id,
    options: {
      selectedModelId: selectedModel?.modelInfo.id,
      selections: currentSelections,
      customOptions,
      quoteRevisionId: quote?.displayRevisionId,
      percentDiscount: quote?.percentDiscount
    }
  } ).data;

  const btnStyle = disabled
    ? {borderBottom: "none", color: "black"}
    : {borderBottom: "1px solid black"};

    return <>
      <div style={infoTextStyle}>(Custom Option)</div>
      <div style={{ ...props.labelStyle,}}>

        <EditCustomOptionButtonModal 
          type="text" className="ghostBmButton"
          style={{padding:0, width: "100%"}}
          onChange={props.onChange}
          disabled={disabled}
          value={customOption}
          categoryId={props.category?.id}
        >
        <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
          <div>
            <span style={{...btnStyle}}>{customOption.content}</span>
          </div>
          <div style={{whiteSpace: "nowrap", minWidth: "5rem", textAlign:"right"}}>
            {(priceDifference || 0) != 0 && 
              <span style={{fontWeight: "600"}}>&nbsp;{Utils.formatMoneyWithSign(priceDifference)}</span>
            }
          </div>
        </div>
        </EditCustomOptionButtonModal>
        {!!customOption.note?.length &&
          <div style={{fontStyle:"italic"}}>Note: {customOption?.note}</div>
        }
        {!!props.category &&
        <ul className="csl" >
          <li style={{fontStyle:"italic"}}>Lead Time (days): {props.category.leadTimeDays}</li>
          {configurator.isEngineering() &&
            <li style={{fontStyle:"italic"}}>Design Time (weeks): {props.category.designTimeWeeks}</li>}
        </ul>
        }
      </div>
    </>;
}

export default AssemblySelectionTable
